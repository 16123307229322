'use client'

import { useState, useEffect, useRef } from 'react'
import 'react-tabs/style/react-tabs.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Box, Button, Divider, TextField } from '@mui/material'
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaCircleChevronUp } from "react-icons/fa6";
import './EnhancedRealEstateLanding.css'
import { FaPhone } from 'react-icons/fa';
import { CiMail } from "react-icons/ci";
import Gallery from './Gallery'
import { IoCall } from "react-icons/io5";
import Location from './Locations'
import { FaArrowRightLong } from "react-icons/fa6";
import FeatureHighlights from './Features'
import Media from './Media'
import BrandsOnBoard from './BrandOnBoard'
import AboutUs from './AboutUs'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { MdClose } from 'react-icons/md'
import { motion } from 'framer-motion';
import { FiSend, FiPhoneCall } from "react-icons/fi";
import QuickContact from './QuickContact'

export default function RealEstateLandingPage() {

    const [scrolled, setScrolled] = useState(false);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',

    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setIsDialogOpen(true);
    }, []);

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    // Handle form field changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Validate form fields
    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.mobile) newErrors.mobile = 'Mobile is required';
        if (!formData.email) newErrors.email = 'Email is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = () => {
        if (validate()) {
            toast.success('Form submitted successfully!');
            setIsDialogOpen(false);
            setFormData({ name: '', mobile: '', email: '' });
        }
    };

    const [isVisible, setIsVisible] = useState({
        hero: false,
        about: false,
        features: false,
        tabs: false,
        gallery: false,
        map: false,
        brands: false,
        cta: false,
        contact: false,
    })

    const [showScrollTop, setShowScrollTop] = useState(false)

    const sectionsRefs = {
        hero: useRef(null),
        about: useRef(null),
        features: useRef(null),
        tabs: useRef(null),
        gallery: useRef(null),
        map: useRef(null),
        brands: useRef(null),
        cta: useRef(null),
        contact: useRef(null),
    };

    const scrollToSection = (sectionName) => {
        const sectionRef = sectionsRefs[sectionName];
        if (sectionRef && sectionRef.current) {
            sectionRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > window.innerHeight) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="enhanced-real-estate-landing">

            <header className={`header ${scrolled ? 'scrolled' : ''}`}>
                <nav className="nav">
                    <div className="nav-container">
                        <img className='brand_logo' onClick={() => scrollToSection('hero')} src="./images/iris-logo.svg" alt="Iris Logo" />
                        <div className="flex items-center content-center">
                            <span className="mr-4 call-now-nav flex items-center content-center">
                                <FiPhoneCall />
                                <p>+91 98117 73111</p>
                            </span>
                            <span className="mr-4 call-now-nav-mobile flex items-center content-center"
                                onClick={() => window.location.href = 'tel:+919811773111'}

                            >
                                <FiPhoneCall />
                            </span>
                            <Button onClick={() => scrollToSection("contact")} className="nav-button">Enquiry Now</Button>
                        </div>
                    </div>
                </nav>
            </header>
            <div>
                {/* Compact Dialog Form */}
                <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
                    <DialogTitle sx={{ fontSize: '1.25rem', padding: '8px 16px', display: 'flex', alignItems: 'center' }}>
                        Contact Us
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseDialog}
                            sx={{ position: 'absolute', right: 8, top: 8, color: 'grey' }}
                        >
                            <MdClose />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers sx={{ padding: '8px 16px' }}>
                        <Box
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1.5,
                            }}
                        >
                            <TextField
                                fullWidth
                                label="Name"
                                variant="outlined"
                                size="small"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                error={!!errors.name}
                                helperText={errors.name}
                            />
                            <TextField
                                fullWidth
                                label="Mobile"
                                variant="outlined"
                                size="small"
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                required
                                error={!!errors.mobile}
                                helperText={errors.mobile}
                                type="tel"
                            />
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                size="small"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                error={!!errors.email}
                                helperText={errors.email}
                                type="email"
                            />

                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ padding: '8px 16px' }}>
                        <Button
                            onClick={() => window.location.href = 'tel:+919811773111'}
                            color="secondary"
                            size="small"
                        >
                            <FiPhoneCall className='mr-1' /> +91 98117 73111
                        </Button>



                        <Button onClick={handleSubmit} color="primary" variant="contained" size="small">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Toast Notification */}
                <ToastContainer position="top-right" autoClose={3000} />
            </div>

            <main>
                <motion.section
                    ref={sectionsRefs.hero}
                    id="hero"
                    className="hero"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <motion.div
                        className="hero-background"
                        initial={{ scale: 1.1 }}
                        animate={{ scale: 1 }}
                        transition={{ duration: 1.5, ease: 'easeOut' }}
                    >
                        <img src="https://www.trehaniris.com/iris-broadway/images/wel-right.jpg" alt="Luxurious home" className="hero-image" />
                        <motion.div
                            className="overlay"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.5 }}
                            transition={{ delay: 0.5, duration: 1 }}
                        ></motion.div>
                    </motion.div>
                    <motion.div
                        className="hero-content"
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3, duration: 1 }}
                    >
                        <h1>THE BROADWAY OF GOOD TIMES</h1>
                        <p>A vibrant fusion of shopping, entertainment, and hospitality that defines the perfect destination for every moment</p>
                        <motion.button
                            className='enquery-btn'
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.6, duration: 0.8 }}
                            onClick={() => setIsDialogOpen(true)}
                        >
                            Enquiry Now
                            <FaArrowRightLong className='ml-3 arrow' size={20} />
                        </motion.button>
                    </motion.div>
                </motion.section>

                <div ref={sectionsRefs.about} className="content-wrapper">
                    <AboutUs />
                </div>
                <section>
                    <FeatureHighlights />
                </section>
                <QuickContact />
                <Location />
                <Gallery />
                <section>
                    <BrandsOnBoard />
                </section>
                <section>
                    <Media />
                </section>
                <section id="contact" ref={sectionsRefs.contact} className="py-20 bg-white">
                    <div className="container mx-auto px-6">
                        <div class="luxury-heading">
                            <div class="luxury-line"></div>
                            <h1>Contact Us </h1>
                            <div class="luxury-line"></div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                            <div
                                initial={{ opacity: 0, x: -20 }}
                                animate={isVisible.contact ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5 }}
                            >

                                <h3 className="text-2xl font-semibold mb-4">Get in Touch</h3>
                                <form className="space-y-4 contact-form">
                                    <TextField label='Name' className='text-field' type="text" placeholder="Enter Name" />
                                    <TextField label="Email" className='text-field' type="email" placeholder="Enter Email" />
                                    <TextField label='Phone' className='text-field' type="tel" placeholder="Enter Phone" />
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Your Message"
                                        multiline
                                        rows={3}

                                        variant="outlined"
                                        maxRows={3}
                                        className='text-field' placeholder="Enter Your Message" />
                                    <Button type="submit" className="w-full button flex items-center">Send Message <FiSend className='ml-3' size={23} /></Button>
                                </form>
                            </div>
                            <div
                                initial={{ opacity: 0, x: 20 }}
                                animate={isVisible.contact ? { opacity: 1, x: 0 } : {}}
                                transition={{ duration: 0.5, delay: 0.2 }}
                                className="space-y-6"
                            >
                                <h3 className="text-2xl font-semibold mb-4">Our Office</h3>
                                <div className="flex items-start space-x-4">
                                    <FaMapMarkerAlt className="text-primary" />
                                    <p>IRIS Broadway, Sector 85-86, Gurugram, Haryana - 122018</p>
                                </div>
                                <div className="flex items-center space-x-4">
                                    <FaPhone className="text-primary" />
                                    <p>+91 98117 73111</p>
                                </div>
                                <div className="flex items-center space-x-4">
                                    <CiMail className="text-primary" />
                                    <p>info@iris-gurugram.com</p>
                                </div>
                                <div className="mt-8">


                                    <div style={{ width: '100%' }}><iframe title='map IRIS' width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=Iris%20Broadway,%20Sector%2085,%20Gurugram,%20Haryana%20122004+(IRIS%20Broadway%20Gurugram)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps systems</a></iframe></div>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer className="footer">
                <div className="footer-content">
                    <img onClick={() => scrollToSection("hero")} src="https://www.trehaniris.com/iris-broadway/images/trehan-logo.svg" alt="Logo" className="footer-logo" />
                    <ul className="footer-links">
                        <li><a onClick={() => scrollToSection("hero")}>Home</a></li>
                        <li><a onClick={() => scrollToSection("about")}>About Us</a></li>
                        <li><a onClick={() => scrollToSection("contact")}>Contact</a></li>
                    </ul>
                </div>
                <div className="footer-bottom">
                    <p>&copy; {new Date().getFullYear()} Trehan Iris. All rights reserved.</p>
                </div>
            </footer>

            {showScrollTop && (
                <button onClick={scrollToTop} className="scroll-top">
                    <FaCircleChevronUp size={24} />
                </button>
            )}
        </div>
    )
}
