import React, { useState, useEffect } from 'react';
import { FaHome, FaSwimmingPool, FaParking, FaDumbbell } from 'react-icons/fa';
import { MdMeetingRoom, MdLocalLaundryService } from 'react-icons/md';
import { GiMoneyStack, GiExitDoor } from 'react-icons/gi';
import { BiBuildingHouse } from 'react-icons/bi';
import { motion } from 'framer-motion';

const features = [
    { icon: FaHome, title: "Fully Furnished", description: "Home-office apartments with high visibility" },
    { icon: GiMoneyStack, title: "Low Maintenance", description: "Cost-effective upkeep" },
    { icon: MdMeetingRoom, title: "Banquet Space", description: "4500 sq. ft. for events" },
    { icon: BiBuildingHouse, title: "Service Floor", description: "Available for Back of House operations" },
    { icon: FaSwimmingPool, title: "Swimming Pool", description: "For leisure and fitness" },
    { icon: GiExitDoor, title: "Separate Entry/Exit", description: "Improved traffic flow" },
    { icon: FaParking, title: "Triple Basement Parking", description: "Ample space for vehicles" },
    { icon: MdLocalLaundryService, title: "Laundry Service", description: "Convenient cleaning solutions" },
    { icon: FaDumbbell, title: "Gym/Spa on Terrace", description: "Wellness facilities with a view" }
];

export default function FeatureHighlights() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="features_wrapper">
            <div className="luxury-heading">
                <div className="luxury-line"></div>
                <h1>Features & Highlights</h1>
                <div className="luxury-line"></div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
                {features.map((feature, index) => (
                    <motion.div
                        key={index}
                        className="bg-white p-6 rounded-lg shadow-md transform hover:-translate-y-1 hover:shadow-xl"
                        initial={{
                            opacity: 0,
                            y: isMobile ? 20 : (index % 2 === 0 ? -100 : 100),  // Move from below on mobile
                            scale: isMobile ? 1 : 0.9  // No scaling on mobile for better performance
                        }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                            scale: 1
                        }}
                        transition={{
                            type: "spring",
                            stiffness: isMobile ? 50 : 100,  // Softer spring on mobile
                            damping: 20,
                            duration: 0.6
                        }}
                        viewport={{ once: false, amount: 0.3 }}
                    >
                        <div className="flex items-center mb-4">
                            <feature.icon className="text-4xl text-indigo-600 mr-4" />
                            <h3 className="text-xl font-semibold text-gray-800">{feature.title}</h3>
                        </div>
                        <p className="text-gray-600">{feature.description}</p>
                    </motion.div>
                ))}
            </div>
        </div>
    );
}
