import React, { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import { motion } from 'framer-motion';

const AboutUs = () => {
    // Define animations
    const fadeIn = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
    };

    const slideInLeft = {
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.6 } },
    };

    const slideInRight = {
        hidden: { opacity: 0, x: 50 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.6 } },
    };

    // State to check screen width
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1024);

    // Update screen size on resize
    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 1024);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="about-us-page">
            {/* About Us Section */}
            <motion.div
                className="luxury-heading"
                variants={isLargeScreen ? fadeIn : {}}
                initial="hidden"
                whileInView="visible"
                style={{ overflow: "hidden" }}
                viewport={{ once: true, amount: 0.2 }}
            >
                <div className="luxury-line"></div>
                <h1>IRIS BROADWAY GURUGRAM</h1>
                <div className="luxury-line"></div>
            </motion.div>
            <motion.div
                className="about-us-page-intro text-content"
                variants={isLargeScreen ? fadeIn : {}}
                initial="hidden"
                whileInView="visible"
                style={{ overflow: "hidden" }}
                viewport={{ once: true, amount: 0.2 }}
            >
                <p className="intro-quote">"Where luxury and convenience meet to create the ultimate shopping experience."</p>
                <p>
                    Iris Broadway Mall, located in Sector 85, Gurugram, is a premier shopping and entertainment destination spread across 2.8 acres. With a 1000 ft. frontage, it serves over 3 lakh residents in the area, offering a mix of dining, fashion, cinema, and leisure activities.
                </p>
                <br />
                <p>
                    The mall features a supermarket, entertainment spaces, and a business hotel, making it the ideal spot to shop, eat, and unwind. We also offer shops for lease, rent, and sale, making it the perfect place for businesses to thrive in a dynamic commercial environment.
                </p>
                <p>Discover the best of shopping and entertainment at Iris Broadway!</p>
            </motion.div>

            {/* Shopping Meets Luxury Section */}
            <div className="about-us-page-section shopping-meets-luxury">
                <motion.div
                    className="about-us-page-text left"
                    variants={isLargeScreen ? slideInLeft : {}}
                    initial="hidden"
                    whileInView="visible"
                    style={{ overflow: "hidden" }}
                    viewport={{ once: false, amount: 0.2 }}
                >
                    <h2 className="heading">Where Shopping Meets Luxury</h2>
                    <ul className="description">
                        <li><FaCheck className='mr-3' /> Premier shopping center in a community of over 3 lakh residents.</li>
                        <li><FaCheck className='mr-3' /> Spans 2.8 acres with a 1000 ft. frontage.</li>
                        <li><FaCheck className='mr-3' /> Distinctive retail destination combining convenience and aspiration.</li>
                    </ul>
                </motion.div>
                <motion.div
                    className="about-us-page-image right"
                    variants={isLargeScreen ? slideInRight : {}}
                    initial="hidden"
                    whileInView="visible"
                    style={{ overflow: "hidden" }}
                    viewport={{ once: false, amount: 0.2 }}
                >

                    <img
                        src="https://geetanjalihomestate.co.in/administrator/assets/property_uploads/iris-broadway-sector-85-1079477-153746.jpg"
                        alt="Shopping Meets Luxury"
                        className="zoom-on-hover"
                    />
                </motion.div>
            </div>

            {/* Hotel Suites Section */}
            <div className="about-us-page-section hotel-suites">
                <motion.div
                    className="about-us-page-image left"
                    variants={isLargeScreen ? slideInLeft : {}}
                    initial="hidden"
                    whileInView="visible"
                    style={{ overflow: "hidden" }}
                    viewport={{ once: false, amount: 0.2 }}
                >
                    <img className="zoom-on-hover" src="https://www.trehaniris.com/iris-broadway/images/hotel-ban-left.jpg" alt="Hotel Suites" />
                </motion.div>
                <motion.div
                    className="about-us-page-text right"
                    variants={isLargeScreen ? slideInRight : {}}
                    initial="hidden"
                    whileInView="visible"
                    style={{ overflow: "hidden" }}
                    viewport={{ once: false, amount: 0.2 }}
                >
                    <h2 className="heading">Hotel Suites at Iris Broadway</h2>
                    <ul className="description desc-hotel">
                        <li><FaCheck className='mr-3' /> Designed for both business and leisure travelers in Gurugram.</li>
                        <li><FaCheck className='mr-3' /> Ideal for quick lunches or relaxing lounge experiences.</li>
                        <li><FaCheck className='mr-3' /> Offers a luxurious and comfortable stay for every need.</li>
                    </ul>
                </motion.div>
            </div>
        </div>
    );
}

export default AboutUs;
