import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { motion } from 'framer-motion'

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <motion.div
                    initial={{ opacity: 0, x: -50 }} // Animates in from the left
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 50 }} // Animates out to the right
                    transition={{ duration: 0.5 }}
                >
                    <Box sx={{ p: 3 }}>{children}</Box>
                </motion.div>
            )}
        </div>
    )
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const Gallery = () => {
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const images = {
        christmas: [
            { src: 'https://www.trehaniris.com/iris-broadway/images/gallery/christmas/christmas-event-6-800x600.jpg', text: 'IRIS Broadway Christmas 2023' },
            { src: 'https://www.trehaniris.com/iris-broadway/images/gallery/christmas/christmas-event-dec-23-01.jpg', text: 'IRIS Broadway Christmas 2023' },
            { src: 'https://www.trehaniris.com/wp-content/uploads/2023/12/christmas-event-dec-23-04.jpg', text: 'IRIS Broadway Christmas 2023' },
            { src: 'https://www.trehaniris.com/wp-content/uploads/2023/02/feb-v1.jpg', text: 'IRIS Broadway Christmas 2023' },
        ],
        valentines: [
            { src: 'https://www.trehaniris.com/iris-broadway/images/valentines-day-feb-3-800x600.jpg', text: 'Valentine\'s Day Celebration 2023' },
            { src: 'https://www.trehaniris.com/iris-broadway/images/valentines-day-feb-5-800x600.jpg', text: 'Valentine\'s Day Celebration 2023' },
            { src: 'https://www.trehaniris.com/iris-broadway/images/gallery/valentines-day/valentines-day-2-800x600.jpg', text: 'Valentine\'s Day Celebration 2023' },
        ],
        independence: [
            { src: 'https://www.trehaniris.com/iris-broadway/images/gallery/independence-day/independence-day-3-800x600.jpg', text: 'Independence Day 2023' },
            { src: 'https://www.trehaniris.com/iris-broadway/images/gallery/independence-day/independence-day-4-800x600.jpg', text: 'Independence Day 2023' },
            { src: 'https://www.trehaniris.com/iris-broadway/images/gallery/independence-day/independence-day-6-800x600.jpg', text: 'Independence Day 2023' },
            { src: 'https://www.trehaniris.com/iris-broadway/images/gallery/independence-day/independence-day-5-800x600.jpg', text: 'Independence Day 2023' },
        ],
        republic: [
            { src: 'https://www.trehaniris.com/iris-broadway/images/republic-day/republicday_24-4.webp', text: 'Republic Day 2023' },
            { src: 'https://www.trehaniris.com/iris-broadway/images/republic-day/republicday_24-5.webp', text: 'Republic Day 2023' },
            { src: 'https://www.trehaniris.com/iris-broadway/images/republic-day/republicday_24-6.webp', text: 'Republic Day 2023' },
        ],
        summerCamp: [
            { src: 'https://www.trehaniris.com/iris-broadway/images/summer-camp/smc3.jpg', text: 'Summer Camp 2023' },
            { src: 'https://www.trehaniris.com/iris-broadway/images/summer-camp/smc4.jpg', text: 'Summer Camp 2023' },
            { src: 'https://www.trehaniris.com/iris-broadway/images/summer-camp/smc5.jpg', text: 'Summer Camp 2023' },
        ],
    }

    const tabs = [
        { key: 'christmas', label: 'Christmas' },
        { key: 'valentines', label: 'Valentine\'s Day' },
        { key: 'independence', label: 'Independence Day' },
        { key: 'republic', label: 'Republic Day' },
        { key: 'summerCamp', label: 'Summer Camp' },
    ]

    return (
        <div className="gallery_wrapper">
            <div className="luxury-heading">
                <div className="luxury-line"></div>
                <h1>Endless Fun-Filled Moments</h1>
                <div className="luxury-line"></div>
            </div>
            <Box sx={{ width: '100%' }}>
                <Tabs centered value={value} onChange={handleChange} aria-label="gallery tabs"
                    variant="scrollable"
                    scrollButtons={false}
                >
                    {tabs.map((tab, index) => (
                        <Tab key={tab.key} label={tab.label} {...a11yProps(index)} />
                    ))}
                </Tabs>
                {tabs.map((tab, index) => (
                    <CustomTabPanel value={value} index={index} key={tab.key}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            {images[tab.key].map((image, imgIndex) => (
                                <div key={imgIndex} className="relative overflow-hidden rounded-lg group">
                                    <img
                                        src={image.src}
                                        alt={image.text}
                                        className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-110"
                                    />
                                    <div className="absolute inset-x-0 bottom-0 h-full bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                        <div className="absolute inset-x-0 bottom-0 p-4 translate-y-full group-hover:translate-y-0 transition-transform duration-300">
                                            <p className="text-white text-center">{image.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </CustomTabPanel>
                ))}
            </Box>
        </div>
    )
}

export default Gallery
