import React from 'react'
import './App.css'
import RealEstateLandingPage from './components/RealEstateLandingPage'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
const App = () => {

  const avatar_url = './images/iris-logo.svg'
  return (
    <div>
      <RealEstateLandingPage />
      <FloatingWhatsApp avatar={avatar_url} phoneNumber={+918279988518} accountName={"IRIS Gurugram"} allowEsc={true} allowClickAway={true} />
    </div>
  )
}

export default App
