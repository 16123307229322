import React, { useState } from 'react';
import { TextField, Button, Box, Divider } from '@mui/material';
import { FiSend, FiPhoneCall } from 'react-icons/fi';

const QuickContact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        setError(false); // Reset error on input change
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Validate if all fields are filled
        if (!formData.name || !formData.email || !formData.phone || !formData.message) {
            setError(true);
            return;
        }
        // Proceed with form submission logic (e.g., API call)
        alert('Message sent successfully!');
    };

    return (
        <div>
            <div className="contact-card bg-white p-6 rounded-lg shadow-lg max-w-full">
                <div className="luxury-heading">
                    <div className="luxury-line"></div>
                    <h1 className="text-2xl font-semibold mb-1">Enquiry Now</h1>
                    <div className="luxury-line"></div>
                </div>
                {/* Contact Form */}
                <form className="space-y-4 contact-form mt-4" onSubmit={handleSubmit}>
                    {/* First Row: Name and Email */}
                    <div className="flex md:space-x-4 text-box ">
                        <TextField
                            label="Name"
                            name="name"
                            className="text-field w-full"
                            type="text"
                            placeholder="Enter Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            error={error && !formData.name}
                        />
                        <TextField
                            label="Email"
                            name="email"
                            className="text-field w-full"
                            type="email"
                            placeholder="Enter Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            error={error && !formData.email}
                        />
                    </div>

                    {/* Second Row: Phone and Message */}
                    <div className="flex md:space-x-4 text-box">
                        <TextField
                            label="Phone"
                            name="phone"
                            className="text-field w-full"
                            type="tel"
                            placeholder="Enter Phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            error={error && !formData.phone}
                        />
                        <TextField
                            label="Your Message"
                            name="message"
                            multiline
                            rows={3}
                            variant="outlined"
                            className="text-field w-full"
                            placeholder="Enter Your Message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            error={error && !formData.message}
                        />
                    </div>

                    {/* Action Buttons Row with Vertical Divider */}
                    <div className="flex items-center space-x-4">
                        <Box
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                width: '100%',
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 2,
                                bgcolor: 'background.paper',
                                color: 'text.secondary',
                                '& svg': {
                                    m: 1,
                                },
                            }}
                        >
                            <Button
                                type="submit"
                                className="w-full flex items-center justify-center bg-blue-600 text-white"
                            >
                                Send Message <FiSend className="ml-3" size={23} />
                            </Button>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Button
                                onClick={() => window.location.href = 'tel:+919811773111'}
                                className="w-full flex items-center justify-center bg-green-600 text-white"
                            >
                                Call Now <FiPhoneCall className="ml-2" size={20} />
                            </Button>
                        </Box>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default QuickContact;
