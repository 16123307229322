import React from 'react'

import '../css/Media.css'
const Media = () => {
    const row1 = [
        "https://www.trehaniris.com/iris-broadway/images/dec_news_front01.jpg",
        "https://www.trehaniris.com/iris-broadway/images/dec_news_back01.jpg",
        "https://www.trehaniris.com/iris-broadway/images/dec_news_front02.jpg",
        "https://www.trehaniris.com/iris-broadway/images/dec_news_back02.jpg",
        "https://www.trehaniris.com/wp-content/uploads/2023/05/HH-AD-Front-Page.jpg",
        "https://www.trehaniris.com/wp-content/uploads/2023/05/HH-AD-Back-Page.jpg"

    ];

    const row2 = [
        "https://www.trehaniris.com/wp-content/uploads/2023/10/NBT-Page-1.webp",
        "https://www.trehaniris.com/wp-content/uploads/2023/10/NBT-Page-2.webp",
        "https://www.trehaniris.com/wp-content/uploads/2023/09/ibg_sep_news01.webp",
        "https://www.trehaniris.com/wp-content/uploads/2023/09/ibg_sep_news02.webp",
        "https://www.trehaniris.com/wp-content/uploads/2023/04/print-april-2023.jpg",
        "https://www.trehaniris.com/wp-content/uploads/2023/03/Jhansi-28-March.jpg"
    ];

    return (
        <div className="AppContainer">
            <div className="Wrapper">
                <div className="media_texts">
                    <div className="Text heading_h1">IRIS Broadway All time in Media</div>
                    <div className="Note">Iris Broadway has been a constant presence in the media, shaping trends and influencing audiences across time.</div>
                </div>


                <div className="Marquee">
                    <div className="MarqueeGroup">
                        {row1.map((el) => (
                            <div className="ImageGroup" key={el}>
                                <img className="Image" src={el} alt="" />
                            </div>
                        ))}
                    </div>
                    <div className="MarqueeGroup">
                        {row1.map((el) => (
                            <div className="ImageGroup" key={el}>
                                <img className="Image" src={el} alt="" />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="Marquee">
                    <div className="MarqueeGroup2">
                        {row2.map((el) => (
                            <div className="ImageGroup" key={el}>
                                <img className="Image" src={el} alt="" />
                            </div>
                        ))}
                    </div>
                    <div className="MarqueeGroup2">
                        {row2.map((el) => (
                            <div className="ImageGroup" key={el}>
                                <img className="Image" src={el} alt="" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Media